//Roboto

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('./styles/extend.scss');

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

// variables global
:root {
  --header-v2-height: 104px;
}
