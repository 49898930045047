.app-container {
  // .hidden {
  //   display: none !important;
  // }
  font-family: 'Segoe UI';
  min-height: calc(100vh - 56px);
  overflow: auto;
  padding-bottom: 60px;
  .bg-main-color {
    background: linear-gradient(90deg, #7e4698, #529dd0);
  }

  .ant-menu-item.ant-menu-item-selected {
    color: #6559f5;
    box-shadow: -3px 0px 0px 0px #6559f5 inset;
  }

  // custom popover
  .ant-popover {
    .ant-popover-arrow {
      margin-left: -24px;
      &:before {
        background: #fff;
      }
    }
    .ant-popover-content {
      left: -64px;
      .ant-popover-inner {
        background: #fff;
        padding: 16px 24px;
      }
    }
  }

  // custom Form ant design
  .ant-form {
    .ant-form-item {
      margin-bottom: 16px;
      .ant-row {
        .ant-col {
          &.ant-form-item-label {
            font-family: Segoe UI;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            color: #262626;
          }
          &.ant-form-item-control {
            .ant-form-item-control-input {
              .ant-form-item-control-input-content {
                .ant-input {
                  color: #262626;
                  &::placeholder {
                    color: #8c8c8c;
                    opacity: 1; /* Firefox */
                  }
                }
                .ant-input-data-count {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
  .ant-upload-list-item-thumbnail {
    > img {
      object-fit: cover !important;
    }
  }
}
// custom modal ant design
.ant-modal.key-modal-custom {
  .ant-modal-content {
    padding: 0px;
  }
  .ant-modal-header {
    padding: 16px 24px;
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 0px;
    .ant-modal-title {
      font-family: Segoe UI;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      color: #262626;
    }
  }
  .ant-modal-body {
    padding: 16px 24px;
  }
  .ant-modal-footer {
    padding: 16px 24px;
    margin-top: 0px;
  }
}
.btn-primary-custom {
  padding: 8px 16px;
  border-radius: 8px;
  background: linear-gradient(90deg, #7d4899 0%, #5498cd 100%);
  font-family: Segoe UI;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #fff;
  width: 100%;
  height: auto;
  &:hover {
    opacity: 0.8;
    color: #fff !important;
  }
  &[disabled],
  &[disabled]:hover,
  &:disabled:hover,
  &:disabled {
    @apply border-gray-5 text-gray-5 bg-gray-3 text-gray-6;
    background: #f5f5f5 !important;
    color: #bfbfbf !important;
  }
}
.btn-outline-custom {
  padding: 5px 16px;
  border-radius: 6px;
  height: auto;
  background: #fff;
  font-family: Segoe UI;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #6559f5;
  border: 1px solid #6559f5;
  width: 100%;
  &:hover {
    opacity: 0.8;
    border: 1px solid #6559f5 !important;
    color: #6559f5 !important;
    background: #fff !important;
  }
}
// table custom - start
.wrap-table {
  border-radius: 8px;
  overflow: hidden;
  @apply font-primary;
  @apply border border-gray-5 dark:border-color-border-dark;
  @apply bg-white dark:bg-color-wrap-dark;
  .table {
    &-title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 12px 24px;
      font-family: 'Segoe UI';
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      @apply text-gray-8 dark:text-white;
      @apply py-3 md:px-6 px-4;
      svg,
      img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
    }
    &-tabs {
      padding: 0 24px;
      .ant-tabs {
        @apply font-primary;
        .ant-tabs-tab {
          font-size: 16px;
        }
        .ant-tabs-ink-bar {
          background: #6559f5 !important;
        }
      }
      .ant-tabs.ant-tabs-top > .ant-tabs-nav {
        margin: 0;
        &::before {
          border-color: transparent;
        }
      }
      .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #6559f5 !important;
        font-weight: 600 !important;
      }
    }
    &-nodata {
      padding: 12px 24px;
      text-align: center;
      @apply text-gray-8 dark:text-white;
      font-family: 'Segoe UI';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }
  .ant-table-wrapper {
    overflow: auto;
    .ant-table {
      font-family: 'Segoe UI';
      background: transparent;
      &-thead {
        tr {
          th {
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px;
            border-top: 1px solid #f0f0f0;
            border-radius: 0 !important;
            // min-width: 100px;
            @apply bg-gray-3 dark:bg-thead-dark;
            @apply dark:border-color-border-dark;
            @apply py-3 md:px-6 px-4;
            &::before {
              display: none;
            }
          }
        }
      }
      &-tbody {
        tr {
          &.ant-table-placeholder {
            background: transparent !important;
            color: inherit;
          }
          td {
            @apply py-3 md:px-6 px-4;
          }
          &:last-child td {
            border: none;
          }
        }
      }
    }
  }
}
.link-explore {
  &:hover svg {
    animation: bouncex 1s infinite;
  }
}
@keyframes bouncex {
  0%,
  100% {
    transform: translateX(25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateX(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}
// table custom - end

// button - start
.ant-btn.ant-btn-primary-outline {
  font-family: 'Segoe UI';
  background: transparent;
  @apply border border-primary text-primary;
  @apply text-base;
  border-radius: 8px;
  padding: 8px 16px;
  height: auto;
  &.ant-btn-sm {
    @apply text-sm;
    height: auto;
    padding: 1px 7px;
    border-radius: 4px;
  }
  &:active,
  &:hover {
    @apply border-blue-5 text-blue-5;
  }
}
.ant-btn.ant-btn-gray-outline {
  font-family: 'Segoe UI';
  background: transparent;
  @apply border border-gray-5 text-gray-9 dark:text-white;
  @apply text-base;
  border-radius: 8px;
  padding: 8px 16px;
  height: auto;
  &.ant-btn-sm {
    @apply text-sm;
    height: auto;
    padding: 1px 7px;
    border-radius: 4px;
  }
  &:active,
  &:hover {
    @apply border-gray-5 text-primary;
  }
}
.ant-btn {
  &[disabled],
  &[disabled]:hover,
  &:disabled:hover,
  &:disabled {
    @apply opacity-100 #{!important};
    @apply bg-gray-3 dark:bg-gray-8 #{!important};
    @apply text-gray-6 #{!important};
    @apply border-gray-5 dark:border-color-border-dark #{!important};
  }
}

// button - end

.pagination-custom {
  &.ant-pagination {
    .ant-pagination-item:not(.ant-pagination-item-active) {
      @apply border-gray-5 dark:border-color-border-dark;
    }
    .ant-pagination-prev,
    .ant-pagination-next {
      .ant-pagination-item-link {
        @apply border-gray-5 dark:border-color-border-dark;
      }
      &.ant-pagination-disabled {
        .ant-pagination-item-link {
          @apply bg-gray-3 dark:bg-gray-9;
        }
      }
    }
  }
}

// modals global css - end
.ant-modal {
  @media (max-width: 767px) {
    width: 90% !important;
  }
}
.warning-generate-key-modal {
  &.text-white {
    .ant-modal .ant-modal-header .ant-modal-title {
      color: #fff;
    }
  }
  .ant-modal {
    max-width: 640px;
    .ant-btn {
      height: 40px;
    }
  }
}
.warning-generate-key-modal .warning-key-modal-footer {
  display: flex;
}
.disconnect-confirm-modal {
  .ant-modal {
    max-width: 320px;
  }
  .disconnect-confirm-modal-footer {
    display: flex;
  }
}

.first-key-info-modal {
  &.text-white {
    .ant-modal .ant-modal-header .ant-modal-title {
      color: #fff;
    }
  }
  .ant-modal {
    max-width: 640px;
    .ant-btn {
      height: 40px;
    }
  }
}
.review-agreement-modal {
  &.text-white {
    .ant-modal .ant-modal-header .ant-modal-title {
      color: #fff;
    }
  }
  .ant-modal {
    width: 500px;
    top: 10px;
    .ant-btn {
      height: 40px;
    }
  }
}
.review-agreement-modal {
  &.text-white {
    .ant-modal .ant-modal-header .ant-modal-title {
      color: #fff;
    }
  }
  .review-agreement-modal-footer {
    display: flex;
    .ant-btn {
      height: 40px;
    }
  }
}
.trade-confirm-modal {
  &.text-white {
    .ant-modal .ant-modal-header .ant-modal-title {
      color: #fff;
    }
  }
  .ant-modal {
    max-width: 375px;
    .ant-btn {
      height: 40px;
    }
  }
}
// react-pdf
.react-pdf__Document {
  width: 100%;
}
.pdf-content {
  &.tos {
    .react-pdf__Document {
      margin-top: -500px;
      @media (max-width: 767px) {
        margin-top: 0px;
      }
    }
  }
  .react-pdf__Document {
    margin-top: -300px;

    @media (max-width: 767px) {
      margin-top: 0px;
    }
  }
  // .react-pdf__Page__canvas {
  //   // margin: 0 auto;
  //   // width: 80% !important;
  //   height: 100% !important;
  // }
}
.bg-antd-container {
  @apply bg-white dark:bg-black-pearl #{!important};
}

.bg-submit-loading {
  width: 100vw;
  height: calc(100% + 56px);
  top: -56px;
  opacity: 0.5;
  background: #000;
  // position: absolute;
  position: fixed;
  z-index: 10;
}

.popup-message {
  .ant-popover-inner {
    padding: 0;
  }
}

.avatar-jazzicon {
  .ant-avatar-string {
    position: unset !important;
  }
}
.signupSteps {
  row-gap: unset;
  height: 260px;
  justify-content: normal;
}
.bottom-sheet-title {
  font-family: Roboto;
  font-size: 23px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: #242424;
  &.text-gradient {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    background: linear-gradient(268deg, #7a509c 0%, #5891c9 99.4%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
  }
}
.bottom-sheet-btn {
  margin-top: 24px;
  background: linear-gradient(268.16deg, #7a509c 0%, #5891c9 99.4%);
  border-radius: 10px;
  font-family: SF Pro Display;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  max-width: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
  .ant-spin {
    margin-right: 8px;
    color: #fff;
  }
}
[data-rsbs-overlay] {
  border-top-left-radius: 32px !important;
  border-top-right-radius: 32px !important;
}
.steps-list {
  margin-top: 28px;
  ul {
    display: flex;
    flex-direction: column;
    margin: 0px;
    padding: 0px;
    li {
      display: flex;
      align-items: center;
      &.step {
        margin-bottom: 12px;
        .step-number {
          margin-right: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          .circle {
            width: 24px;
            height: 24px;
            position: relative;
            &.checked {
              display: inline-block;
              border-radius: 50%;
              background: #6eb24e;
              padding: 5px;
            }
            &.number {
              background: #fff;
              border: 2px solid transparent;
              border-radius: 50%;
              &:before {
                content: '';
                position: absolute;
                inset: 0;
                z-index: -1;
                margin: -3px;
                background-image: linear-gradient(268deg, #7a509c 0%, #5891c9 99.4%);
                border-radius: inherit;
              }
            }
            svg {
              width: 24px;
              height: 24px;
            }
            .label {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              color: #000;
              text-align: center;
              font-family: 'SF Pro Display';
              font-size: 13px;
              font-style: normal;
              font-weight: 700;
            }
          }
        }
        .step-title {
          color: #000;
          font-family: 'SF Pro Display';
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
    }
  }
}
.ant-drawer {
  .ant-drawer-mask {
    background: transparent;
  }
  .ant-drawer-content {
    &.layout-drawer {
      background: #090a16;
      .ant-drawer-header {
        .ant-drawer-header-title {
          .ant-drawer-close {
            color: #fff;
          }
        }
      }
    }
  }
}

.layout-main-container {
  @media (max-width: 767px) {
    overflow-x: hidden;
  }
  > div {
    transition: all 0.3s;
    left: 0px;
    position: relative;
    &.drawer-open {
      left: 70%;
      .navbarV2 {
        left: 70%;
        .drawer-button {
          display: none;
        }
      }
    }
  }
}
@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}
