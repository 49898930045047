.containerLoading {
  position: absolute;
  width: 100vw;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  z-index: 9999;
}
