/* GLOBAL STYLES
   ========================================================================== */

html,
body {
  font-family: 'Nunito Sans', sans-serif;
  background-color: #ffffff;
  color: #3a3a3c;
  min-width: 375px;
  scroll-behavior: smooth;
  height: auto;
}

/**
  * Apply border-box style to all elements
  */

* {
  box-sizing: border-box;
}

/**
  * Remove underline of <a> tag.
  */

a {
  text-decoration: none;
}

@font-face {
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: normal;
  src: local('/fonts/Segoe UI Regular'), url('./fonts/Segoe UI.woff') format('woff');
}
@font-face {
  font-family: 'Segoe UI';
  font-style: normal;
  font-weight: 700;
  src: local('/fonts/Segoe UI Bold'), url('./fonts/Segoe UI Bold.woff') format('woff');
}
@font-face {
  font-family: 'Segoe UI';
  font-style: italic;
  font-weight: normal;
  src: local('/fonts/Segoe UI Italic'), url('./fonts/Segoe UI Italic.woff') format('woff');
}
@font-face {
  font-family: 'Segoe UI';
  font-style: italic;
  font-weight: 700;
  src: local('/fonts/Segoe UI Bold Italic'), url('./fonts/Segoe UI Bold Italic.woff') format('woff');
}
@font-face {
  font-family: 'Segoe UI';
  font-style: italic;
  font-weight: 600;
  src: local('/fonts/Segoe UI Bold Italic'), url('./fonts/Segoe UI Bold Italic.woff') format('woff');
}
