.DrawerContent {
  :global {
    .nav-logo {
      width: 82px;
      margin-bottom: 30px;
    }
    .button-connect-container {
      margin-bottom: 30px;
      > button {
        border-radius: 8.333px;
        background: #0d69d7;
        padding: 11px 27px;
        color: #fff;
        height: auto;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
    .nav-links {
      ul {
        li {
          margin-bottom: 30px;
          color: #fff;
          font-family: Poppins;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          transition: all 0.3s ease-in-out;
          &:hover {
            color: #2a73ff;
          }
          a {
            text-decoration: none;
            &.active {
              color: #2a73ff;
            }
          }
        }
      }
    }
  }
}
