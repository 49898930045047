.text-purple {
    color: #9900ff;
  }
  .section-gradient {
    background: linear-gradient(to right, #f0dcff 0%, #a7cbef 100%);
    background: -moz-linear-gradient(to right, #f0dcff 0%, #a7cbef 100%);
    background: -o-linear-gradient(to right, #f0dcff 0%, #a7cbef 100%);
    background: -ms-linear-gradient(to right, #f0dcff 0%, #a7cbef 100%);
    background: -webkit-linear-gradient(left, #f0dcff 0%, #a7cbef 100%);
  }
  
  .section-heading {
    color: #9900ff;
    margin-bottom: 1rem;
    font-weight: 700;
  }
  .section-sub-heading {
    line-height: 1;
    color: #4d4d4d;
    margin-bottom: 2rem;
  }
  .section-button-outline {
    padding: 8px 20px;
    cursor: pointer;
    background: none transparent;
    border: 1px solid #9900ff;
    text-align: center;
    box-sizing: border-box;
    z-index: 16;
    user-select: none;
    font-size: 19px;
    font-weight: 600;
    color: #9900ff;
    border-radius: 4px;
    transition: box-shadow 200ms ease 0s, background 200ms ease 0s;
    &:hover {
      background: #9900ff;
      color: #fff;
    }
  }
  
  .text-black {
    color: #4d4d4d;
  }
  