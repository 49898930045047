.FooterV2 {
  :global {
    .footer-container {
      background: linear-gradient(106deg, #a7c9ec 0%, #eddbff 98.89%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 32px 24px;
      position: relative;
      z-index: 2;
      .footer-logo {
        margin-bottom: 48px;
        img {
          width: 104px;
        }
      }
      .social-links {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 32px;
        svg {
          width: 48px;
          height: 48px;
          margin: 0px 24px;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          &:hover {
            color: #2a73ff;
          }
        }
      }
      .download-apps {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 32px;
        > a {
          margin: 0px 4px;
        }
        .app-store {
          width: 144px;
        }
        .google-store {
          width: 160px;
        }
      }
      .term-section {
        > a {
          color: #000;
          font-family: Poppins;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          transition: all 0.3s ease-in-out;
          margin: 0 16px;
          &:hover {
            color: #2a73ff;
          }
        }
      }
    }
  }
}
