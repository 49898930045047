.bg-item-selected {
  background: linear-gradient(
    90deg,
    rgba(126, 70, 152, 0.1) 0%,
    rgba(82, 157, 208, 0.1) 100%
  ) !important;
}

.bg-main-color {
  background: linear-gradient(90deg, #7e4698 0%, #529dd0 100%) !important;
}

.text-main-color {
  background: linear-gradient(90deg, #7e4698 0%, #529dd0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  .unicode {
    -webkit-text-fill-color: initial;
  }
}

.max-chat-content-height {
  max-height: calc(100vh - 265px);
  overflow-y: auto;
}

.ant-tabs-ink-bar {
  background: linear-gradient(90deg, #7e4698 0%, #529dd0 100%);
}

.emoji-mart-scroll {
  border-bottom: 0;
  margin-bottom: 6px;
}

.emoji-mart-scroll + .emoji-mart-bar {
  display: none;
}

.ant-popover-inner-content {
  #preview {
    display: none !important;
  }
}

.shadow-none-important {
  box-shadow: none !important;
}

.context {
  display: none;
}

.msg:hover .context {
  display: flex;
  justify-content: center;
  align-items: center;
}

.context-detail {
  display: none;
}

.group-card {
  position: relative;
}

.group-card:hover .context-detail {
  display: block;
}

.ant-popover-inner-content {
  padding: 0px;
}

.ant-popover-inner {
  border-radius: 10px;
}

.ant-tabs-ink-bar {
  background: #7e4698 !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #7e4698 !important;
}

.gridGallery {
  display: flex;
  flex-wrap: wrap;
}

.ReactGridGallery_tile {
  background: none !important;
}

.ant-tabs-nav::before {
  border-bottom: 0px !important;
}

.fieldset {
  border: 2px solid;
  color: #bebcf6;
  border-radius: 1em;
  padding: 0 1em 1em;

  .legend {
    transform: translatey(-50%);
    background-color: white;
    padding: 0 0.15em;
    color: #7e4698;
    font-weight: 600;
  }
}

.text {
  color: '#000';
}

.username {
  .ant-dropdown-menu-title-content {
    width: 100% !important;
  }
}

.timeline-dark {
  .ant-timeline-item-head {
    background-color: transparent;
  }
}

.timeline-light {
  .ant-timeline-item-head {
    background-color: #fff;
  }
}

.bg-claim {
  background: linear-gradient(269.78deg, #00afff 0.16%, #484aff 50.31%, #ba42ff 102.51%) !important;
}

.nft-image {
  position: relative;
}

.nft-image img {
  transition: transform 0.4s;
  width: 100%;
  height: 100%;
}

.nft-image:hover img {
  transform: scale(1.05);
  transform-origin: 50% 50%;
}

.emoji-font {
  font-family: Helvetica Neue, apple color emoji, sans-serif;
}
