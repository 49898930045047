.navbarV2 {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  transition: all 0.3s;
  :global {
    .navbar-container {
      display: flex;
      padding: 32px 54px;
      width: 100%;
      transition: all 0.3s ease-in-out;
      @media (max-width: 767px) {
        padding: 12px 20px;
        justify-content: center;
        position: relative;
      }
      &.scrolled {
        padding: 12px 54px;
        background: #101124;
        @media (max-width: 767px) {
          padding: 12px 20px;
        }
      }
      .gap-x-10 {
        @media (max-width: 767px) {
          column-gap: normal;
        }
      }
      .drawer-button {
        display: none;
        left: 24px;
        svg {
          color: #fff;
          width: 16px;
        }
        @media (max-width: 767px) {
          display: block;
          position: absolute;
        }
      }
    }

    .nav-logo {
      @media (max-width: 767px) {
        margin: 0 auto;
      }
      img {
        width: auto;
        height: 26px;
      }
    }
    .nav-left {
      .nav-links {
        @media (max-width: 767px) {
          display: none;
        }
        ul {
          display: flex;
          align-items: center;
          li {
            margin-left: 60px;
            color: #fff;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            transition: all 0.3s ease-in-out;
            &:hover {
              color: #2a73ff;
            }
            a {
              text-decoration: none;
              &.active {
                color: #2a73ff;
              }
            }
          }
        }
      }
    }
    .nav-right {
      @media (max-width: 767px) {
        display: none;
      }
      .current-lang {
        display: flex;
        align-items: center;
        margin-right: 40px;
        > svg {
          margin-left: 4px;
        }
      }
      .connect-button-content {
        .chakra-button {
          border-radius: 10px;
          padding: 11px 27px;
          background: #0d69d7;
          color: #fff;
          font-family: Poppins;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          box-shadow: none;
          &:hover {
            box-shadow: 0 0 8px #0d69d7, 0 0 12px rgba(84, 151, 204, 0.6);
          }
        }
      }
    }
  }
}
