.user-avatar {
    text-align: center;
}
.username-section {
    padding: 15px;
    margin-top: 10px;
    width: 100%;
}
.username-input {
    width: 220px;
}
.save-button {
    width: 120px;
}
.line {
    border: 0.5px solid rgb(210, 205, 211);
}
.bottom {
    margin-bottom: 50px;
}